import { StockEquipmentItemViewProps } from './StockEquipmentRow';
import React from 'react';
import { Box, Grid, TableCell } from '@mui/material';
import { EQUIPMENT_STATES } from '../../constants/AppConstants';
import { Edit } from '@mui/icons-material';

const StockEquipmentCellView = ({ stockBatchElement, handleState, onEdit }: StockEquipmentItemViewProps) => (
  <TableCell
    onClick={onEdit}
    sx={{
      position: 'relative',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
      },
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} sm={handleState ? 4 : 6}>
        {stockBatchElement.serialNumber}
      </Grid>
      <Grid item xs={12} sm={handleState ? 4 : 6}>
        {stockBatchElement.equipmentName}
      </Grid>
      {handleState && (
        <Grid item xs={12} sm={4}>
          {EQUIPMENT_STATES.getNameById(stockBatchElement.state)}
        </Grid>
      )}
      <Box
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
        }}
      >
        <Edit />
      </Box>
    </Grid>
  </TableCell>
);

export default StockEquipmentCellView;
