import { StockEquipmentItemEditProps } from './StockEquipmentRow';
import { addValidationRule } from 'formsy-react';
import { Values } from 'formsy-react/dist/interfaces';
import React, { useEffect, useState } from 'react';
import { Equipment, EquipmentStateId, StockEquipment } from '../../model/model';
import { useAppSelector } from '../../hooks';
import SerialNumberField, { SerialNumberConstraints } from '../utils/SerialNumberField';
import { Grid, TableCell } from '@mui/material';
import { AutocompleteField, TextField } from '../utils';
import { EQUIPMENT_STATES_RECEPTION } from '../../constants/AppConstants';

const errorMessages = {
  equipmentCode: {
    isDefaultRequiredValue: 'Veuillez saisir un type de matériel',
    isExisty: 'Veuillez saisir un type de matériel',
  },
  state: {
    isDefaultRequiredValue: 'Veuillez sélectionner un statut',
    isExisty: 'Veuillez sélectionner un statut',
  },
  serialNumber: {
    uniqueSerialNumber: 'Matériel saisi en doublon',
    isAlpha: 'Format alphabétique attendu pour ce matériel',
    isAlphanumeric: 'Format alphanumérique attendu pour ce matériel',
    isHexadecimal: 'Format hexadecimal attendu pour ce matériel',
    isNumeric: 'Format numérique attendu pour ce matériel',
  },
};

addValidationRule('uniqueSerialNumber', (values: Values, value): boolean => {
  return Object.entries(values).filter(([k, v]) => k.startsWith('serial-number-') && v === value).length < 2;
});

const StockEquipmentCellEdit = ({ stockBatchElement, onEquipmentChange, handleState }: StockEquipmentItemEditProps) => {
  const { id } = stockBatchElement;
  const [serialNumber, setSerialNumber] = useState(stockBatchElement.serialNumber);
  const [equipmentName, setEquipmentName] = useState(stockBatchElement.equipmentName);
  const [equipmentCode, setEquipmentCode] = useState(stockBatchElement.equipmentCode);
  const [state, setState] = useState(stockBatchElement.state);
  const [found, setFound] = useState(false);
  const availableEquipments: Equipment[] = useAppSelector(({ equipments: { equipments } }) => equipments || []);

  const [constraints, setConstaints] = useState<SerialNumberConstraints>();
  useEffect(() => {
    onEquipmentChange({
      id,
      serialNumber,
      state,
      equipmentCode,
      equipmentName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, serialNumber, equipmentName, equipmentCode, state]);

  useEffect(() => {
    //state can be updated by parent component by changing default state
    setState(stockBatchElement.state);
  }, [stockBatchElement.state]);

  const handleEquipmentFoundBySerialNumber = (equipmentFound: StockEquipment | null | undefined) => {
    setFound(Boolean(equipmentFound));
    if (equipmentFound) {
      setEquipmentName(equipmentFound.equipmentName);
      setEquipmentCode(equipmentFound.equipmentCode);
    }
  };

  const handleSerialNumberChange = (newSerialNumber: string) => setSerialNumber(newSerialNumber);

  const handleStateChange = (state: EquipmentStateId) => setState(state);

  const handleEquipmentChange = (_equipmentId: string, equipment: Equipment | null | undefined) => {
    if (equipment) {
      setEquipmentName(equipment.name);
      setEquipmentCode(equipment.code);

      setConstaints({
        minCharacters: equipment.minCharacters,
        maxCharacters: equipment.maxCharacters,
        serialNumberType: equipment.serialNumberType,
        unauthorizedCharacters: equipment.unauthorizedCharacters,
      });
    }
  };

  return (
    <TableCell>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={handleState ? 4 : 6}>
          <SerialNumberField
            name={`serial-number-${id}`}
            onChange={handleSerialNumberChange}
            onEquipmentLoaded={handleEquipmentFoundBySerialNumber}
            value={serialNumber}
            constraints={constraints}
            validations="uniqueSerialNumber"
            validationErrors={errorMessages.serialNumber}
          />
        </Grid>
        <Grid item xs={12} sm={handleState ? 4 : 6}>
          {found ? (
            <TextField disabled name="type" placeholder="Type du matériel" fullWidth value={equipmentName} />
          ) : (
            <AutocompleteField
              options={availableEquipments}
              placeholder="Type du matériel"
              fullWidth
              name={`equipmentCode-${id}`}
              value={equipmentCode}
              getOptionValue={({ code }: Equipment) => code}
              getOptionLabel={({ name }: Equipment) => name}
              isOptionEqualToValue={(option: Equipment, value: Equipment) => option.code === value.code}
              onChange={handleEquipmentChange}
              validations={serialNumber ? { isExisty: true } : {}}
              validationErrors={errorMessages.equipmentCode}
            />
          )}
        </Grid>
        {handleState && (
          <Grid item xs={12} sm={4}>
            <AutocompleteField
              options={EQUIPMENT_STATES_RECEPTION}
              placeholder="Statut"
              fullWidth
              name={`state-${id}`}
              value={state}
              clearIcon={false}
              onChange={handleStateChange}
              validations="isExisty"
              validationErrors={errorMessages.state}
            />
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

export default StockEquipmentCellEdit;
