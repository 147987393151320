import React, { useEffect, useState } from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { StockBatchElement } from '../../model/model';
import StockEquipmentCellEdit from './StockEquipmentCellEdit';
import StockEquipmentCellView from './StockEquipmentCellView';

type StockEquipmentItemProps = {
  stockBatchElement: StockBatchElement; // equipment handled
  handleState: boolean; //to display status change on the screen
};

export type StockEquipmentItemEditProps = StockEquipmentItemProps & {
  onEquipmentChange: (stockEquipment: StockBatchElement) => void; //callback to handle equipment change on parent component
};

export type StockEquipmentItemViewProps = StockEquipmentItemProps & {
  onEdit: () => void;
};

type StockEquipmentRowProps = StockEquipmentItemEditProps & {
  onRemoveItem: (id: string) => void; //callback to remove item from the parent component
};

const batchElementIsIncomplete = (serialNumber: string, equipmentCode: string) => !serialNumber || !equipmentCode;

const StockEquipmentRow = ({
  stockBatchElement,
  onRemoveItem,
  onEquipmentChange,
  handleState,
}: StockEquipmentRowProps) => {
  const [editing, setEditing] = useState(() =>
    batchElementIsIncomplete(stockBatchElement.serialNumber, stockBatchElement.equipmentCode),
  );

  useEffect(() => {
    setEditing(batchElementIsIncomplete(stockBatchElement.serialNumber, stockBatchElement.equipmentCode));
  }, [stockBatchElement.serialNumber, stockBatchElement.equipmentCode]);

  return (
    <TableRow>
      {editing ? (
        <StockEquipmentCellEdit
          stockBatchElement={stockBatchElement}
          onEquipmentChange={onEquipmentChange}
          handleState={handleState}
        />
      ) : (
        <StockEquipmentCellView
          stockBatchElement={stockBatchElement}
          handleState={handleState}
          onEdit={() => setEditing(true)}
        />
      )}
      <TableCell padding="checkbox" width={0}>
        <Tooltip title="Supprimer le matériel">
          <IconButton onClick={() => onRemoveItem(stockBatchElement.id)} size="large">
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default StockEquipmentRow;
