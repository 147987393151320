import queryString from 'query-string';

import {
  EQUIPMENT_MOVE_FILTER_UPDATED,
  EQUIPMENT_MOVE_LOADED,
  EQUIPMENT_MOVE_SEND_ERRORS,
  EQUIPMENT_MOVE_SEND_STATUSES,
  EQUIPMENT_MOVE_UPDATED,
  EQUIPMENT_STATES,
  urls,
} from '../constants/AppConstants';
import { api, ignore401 } from '../services/RestService';
import { paginationToParams, paramsToPagination } from '../utils/paging';
import { addGlobalError, addGlobalMessage } from './SnackbarActions';
import history from '../history';
import { PagedResponse, Pagination, PaginationParams } from '../model/pagination';
import { AppDispatch } from '../store';
import { ApiEquipmentMove, EquipmentMove, EquipmentMoveCreateCommand } from '../model/model';

/**
 * The REST collection used to manage outlet resources.
 */
const equipmentMoveCollection = api.all(urls.equipmentMoves);
export const equipmentMoveExportUrl = `${equipmentMoveCollection.url()}/export.csv`;

const transformMove = (move: ApiEquipmentMove): EquipmentMove => ({
  ...move,
  state: EQUIPMENT_STATES.getById(move.state),
  sendStatus: EQUIPMENT_MOVE_SEND_STATUSES.getById(move.sendStatus),
  sendError: EQUIPMENT_MOVE_SEND_ERRORS.getById(move.sendError),
});
const transformPage = (paged: PagedResponse<ApiEquipmentMove>): PagedResponse<EquipmentMove> => ({
  ...paged,
  content: paged.content.map(transformMove),
});

const equipmentMoveFilterUpdated = (pagination: Pagination) => {
  const { pageSize, filter } = pagination;
  return {
    type: EQUIPMENT_MOVE_FILTER_UPDATED,
    pageSize,
    filter,
  };
};

export const updateEquipmentMoveFilter = (pagination: Pagination) => {
  history.push({
    search: queryString.stringify(paginationToParams(pagination)),
  });
};

export const loadEquipmentMoves = (params: PaginationParams) => (dispatch: AppDispatch) => {
  const pagination = paramsToPagination(params);
  dispatch(equipmentMoveFilterUpdated(pagination));
  equipmentMoveCollection
    .getAll(paginationToParams(pagination))
    .then((response) => {
      const page = transformPage(response.body(false));
      // keep filters
      dispatch({
        type: EQUIPMENT_MOVE_LOADED,
        page,
      });
    })
    .catch(ignore401);
};

export const saveEquipmentMove = (equipmentMove: EquipmentMoveCreateCommand) => (dispatch: AppDispatch) => {
  equipmentMoveCollection
    .post(equipmentMove)
    .then((response) => {
      const move: ApiEquipmentMove = response.body(false);
      history.push({ pathname: '/equipment-moves' });
      if (move?.sendStatus === 'SUCCESS') {
        dispatch(addGlobalMessage('Le mouvement a bien été envoyé'));
      } else if (move?.sendStatus === 'PENDING') {
        // Si on n'a pas reçu la réponse Salesforce à temps, on n'affiche pas d'erreur
        dispatch(addGlobalMessage('Le mouvement a été envoyé. Résultat en attente.'));
      } else {
        const sendStatus = EQUIPMENT_MOVE_SEND_STATUSES.getById(move.sendStatus);
        if (sendStatus) {
          dispatch(addGlobalError(`Mouvement refusé : ${sendStatus.name}`));
        } else {
          dispatch(addGlobalError('Le mouvement été refusé'));
        }
      }
    })
    .catch(ignore401)
    .catch((err) => {
      console.error('Error while saving equipment move:', err);
      if (err.response && err.response.data && err.response.statusCode === 400) {
        dispatch(addGlobalError(err.response.data.error));
      } else {
        dispatch(addGlobalError("Erreur lors de l'envoi"));
      }
    });
};

export const toggleEquipmentMoveFixed = (equipmentMove: EquipmentMove) => (dispatch: AppDispatch) => {
  const verb = equipmentMove.sendStatus.id === 'ERROR' ? 'fix' : 'unfix';
  equipmentMoveCollection
    .custom(equipmentMove.id)
    .custom(verb)
    .post()
    .then((response) => {
      const updatedMove = transformMove(response.body(false));
      if (verb === 'fix') {
        dispatch(addGlobalMessage('Le mouvement a été marqué comme corrigé'));
      } else {
        dispatch(addGlobalMessage('Le mouvement a été marqué comme non corrigé'));
      }
      dispatch({
        type: EQUIPMENT_MOVE_UPDATED,
        move: updatedMove,
      });
    })
    .catch(ignore401)
    .catch((err) => {
      console.error(`Error while ${verb}ing equipment move:`, err);
      if (err.response && err.response.data && err.response.statusCode === 400) {
        dispatch(addGlobalError(err.response.data.error));
      } else {
        dispatch(addGlobalError('Erreur lors de la mise à jour'));
      }
    });
};
